import React, { useMemo } from 'react'

import type { ITimelineViewMode } from '@/components/shared/components/timeline/TimelineView'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'

interface IPropTypes {
  onChange: (value: ITimelineViewMode) => void
  defaultView?: ITimelineViewMode
}
export default function TimelineViewToggle({
  onChange,
  defaultView,
}: IPropTypes) {
  const t = useTranslations('common.timelineView')

  const handleChangeView = (selected: ISelectOptionGroup) => {
    onChange((selected as ISelectOption).value)
  }

  const options = useMemo(
    () => [
      { label: t('year'), value: 'year' },
      { label: t('quarter'), value: 'quarter' },
      { label: t('month'), value: 'month' },
    ],
    []
  )
  return (
    <Select
      size='xs'
      defaultValue={options.filter((o) => o.value === (defaultView || 'month'))}
      options={options}
      placeholder='Select view'
      onChange={handleChangeView}
    />
  )
}
