import { ArrowUUpLeft } from '@phosphor-icons/react'
import clsx from 'clsx'
import { startOfDay } from 'date-fns'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import type {
  OnItemDragObjectResize,
  ReactCalendarGroupRendererProps,
  ReactCalendarItemRendererProps,
  TimelineItemBase,
} from 'react-calendar-timeline'
import Timeline, {
  CustomMarker,
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
} from 'react-calendar-timeline'

import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import {
  addDaysToDate,
  dayOfYear,
  formatDate,
  isSameDay,
  parseUnixTimeToDate,
} from '@/lib/helpers/dateHelpers'

export interface ITimelineItem
  extends Omit<TimelineItemBase<number>, 'start_time' | 'end_time'> {
  title: string
  color?: string
  id: string | number
  start_time?: number
  end_time?: number
  hasEnd?: boolean
  hasStart?: boolean
  isGroup?: boolean
  height?: number
  [key: string]: any
}
export interface ITimelineGroupProps
  extends ReactCalendarGroupRendererProps<{
    id: any
    title: any
    [key: string]: any
  }> {
  onUpdate?: (data: any) => void
  canEdit?: boolean
}

export type ITimelineViewMode = 'year' | 'month' | 'quarter'
interface IPropTypes {
  items: ITimelineItem[]
  groupRenderer?: (props: ITimelineGroupProps) => React.ReactNode
  itemRenderer?: (props: ReactCalendarItemRendererProps<any>) => React.ReactNode
  canResize?: false | 'both' | 'left' | 'right'
  canMove?: boolean
  canChangeGroup?: boolean
  canEdit?: boolean
  viewMode?: ITimelineViewMode
  onItemMove?: (itemId: any, dragTime: number, newGroupOrder: number) => void
  onItemResize?: (
    itemId: any,
    endTimeOrStartTime: number,
    edge: 'left' | 'right'
  ) => void
}

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start_time',
  itemTimeEndKey: 'end_time',
  groupLabelKey: 'title',
}
const RANGE_MAP = {
  month: {
    range: 15,
    zoom: 30 * 86400 * 1000,
  },
  quarter: {
    range: 60,
    zoom: 120 * 86400 * 1000,
  },
  year: {
    range: 182,
    zoom: 365.24 * 86400 * 1000,
  },
}

const generateRange = (viewMode: ITimelineViewMode, date?: Date) => {
  const baseDate = date || new Date()
  const start = addDaysToDate(
    baseDate,
    RANGE_MAP[viewMode].range * -1
  ).getTime()
  const end = addDaysToDate(baseDate, RANGE_MAP[viewMode].range).getTime()
  return { start, end }
}

export default function TimelineView({
  items: _items,
  groupRenderer,
  canResize = false,
  canMove = false,
  canChangeGroup = false,
  viewMode = 'month',
  onItemMove,
  onItemResize,
}: IPropTypes) {
  const t = useTranslations('ui.timeline')

  const initialRange = useRef(generateRange(viewMode))
  const [range, setRange] = useState(initialRange.current)
  const [draggingItem, setDraggingItem] = useState<Partial<ITimelineItem>>()
  const handleResetView = (date?: Date) => {
    setRange(generateRange(viewMode, date))
  }
  const zoom = useMemo(() => RANGE_MAP[viewMode].zoom, [viewMode])
  const items = useMemo(
    () =>
      _items.map((item) => ({
        ...item,
        hasEnd: !!item.end_time,
        hasStart: !!item.start_time,
        start_time: item.start_time || new Date().setHours(0, 0, 0, 0),
        end_time: item.end_time || new Date().setHours(59, 59, 59, 999),
        canResizeLeft: !!item.start_time,
        canResizeRight: !!item.end_time,
      })),
    [_items]
  )

  const groups = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        id: item.id,
        title: item.title,
      })),
    [items]
  )

  const timeSteps = useMemo(() => {
    let day = 7
    if (viewMode === 'year') day = 15
    else if (viewMode === 'month') day = 1
    return day
  }, [viewMode])

  const headerUnit = useMemo(() => {
    if (viewMode === 'year') {
      return 'month'
    }
    return 'primaryHeader'
  }, [viewMode])

  useEffect(() => {
    setRange(generateRange(viewMode))
  }, [viewMode])

  const rangeChanged = useMemo(
    () => range.start !== initialRange.current.start,
    [range.start]
  )

  const itemRenderer = ({
    item,
    itemContext,
    getItemProps,
    getResizeProps,
  }: ReactCalendarItemRendererProps<any>) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
    const percentages = (itemContext.dimensions.width * item.percentage) / 100

    const renderColor = () => {
      if (!item.hasEnd)
        return '!border-dashed !border-[0px] w-[50%] !bg-gradient-to-r  !bg-gray4 !from-gray6/30 !to-transparent  !bg-transparent'
      if (item.overdue)
        return '!border-gray5/50 w-[50%] redline border  !bg-gradient-to-r !from-transparent !to-red8/20  !bg-red8/20'
      if (item.percentage === 100)
        return '!border-green8 border   !bg-green7/50'
      return '!border-gray5 border   !bg-gray4'
    }

    if (item.isGroup) return <></>

    return (
      <div
        {...getItemProps({})}
        style={{
          width: `${itemContext.dimensions.width}px`,
          top: `${itemContext.dimensions.top}px`,
          height: `${itemContext.dimensions.height}px`,
          left: `${itemContext.dimensions.left}px`,
          zIndex: 80,
          cursor: 'pointer',
          position: 'absolute',
          lineHeight: '30px',
          fontSize: '12px',
          color: 'initial',
          // background: 'rgb(33, 150, 243)',
          // border: '1px solid rgb(26, 111, 179)',
          boxSizing: 'border-box',
          display: item.hasStart ? 'block' : 'none',
        }}
        className={clsx(
          renderColor(),
          '!z-[2] block rounded-md !border-[0.5px] !border-gray5 ',
          itemContext.selected ? '!border-x-2' : '',
          item.hasEnd ? '' : `!w-[${itemContext.dimensions.width + 200}px]`
        )}
      >
        {itemContext.useResizeHandle && itemContext.canResizeLeft ? (
          <div
            {...leftResizeProps}
            className='!left-[-10px] !w-[10px] !cursor-col-resize'
          />
        ) : null}
        <div className='flex w-full'>
          <div className='sticky left-0 flex items-center space-x-2 overflow-hidden py-0 pl-2 pr-1'>
            <div className='shrink truncate'>
              <span className='truncate text-left font-medium text-gray10 '>
                {item.title}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundImage:
              'repeating-linear-gradient(45deg, transparent 0 4px, #8787871a 0px 5px)',
            width: `${percentages}px`,
            borderRadius: '8px',
          }}
          className='absolute top-0 block h-full'
        />
        {!item.hasEnd && (
          <div className='gnattgradient-r pointer-events-none absolute inset-y-[0px] left-0 w-[50%] rounded-l-md border-y border-l  border-dashed border-gray6 ' />
        )}
        {itemContext.useResizeHandle && itemContext.canResizeRight ? (
          <div
            {...rightResizeProps}
            className='!right-[-10px] !w-[10px] !cursor-col-resize'
          />
        ) : null}
        {item.overdue && (
          <>
            <div
              style={{
                backgroundImage:
                  'repeating-linear-gradient(45deg, transparent 0 4px, #ff000021 0px 5px)',
                width: `${percentages}px`,
                position: 'absolute',
                top: 0,
                height: '100%',
                borderRadius: '8px',
              }}
            />
            <div className='gnattgradient-r pointer-events-none absolute inset-y-[0px] left-0 w-[50%] rounded-l-md border-y border-l border-red8' />
          </>
        )}
      </div>
    )
  }

  return (
    <div className={items.length ? 'rtc-not-empty' : 'rtc-empty'}>
      <Timeline
        items={items}
        groups={groups}
        keys={keys}
        visibleTimeStart={range.start}
        visibleTimeEnd={range.end}
        onTimeChange={(start, end) => setRange({ start, end })}
        groupRenderer={groupRenderer}
        itemRenderer={itemRenderer}
        sidebarWidth={280}
        lineHeight={50}
        itemHeightRatio={0.6}
        canResize={canResize}
        canMove={canMove}
        canChangeGroup={canChangeGroup}
        maxZoom={zoom}
        minZoom={zoom}
        onItemMove={(itemId, dragTime, newGroupOrder) => {
          setDraggingItem(undefined)
          onItemMove?.(itemId, dragTime, newGroupOrder)
        }}
        onItemResize={(itemId, time, edge) => {
          setDraggingItem(undefined)
          onItemResize?.(itemId, time, edge)
        }}
        onItemDrag={({ itemId, edge, time }: OnItemDragObjectResize) => {
          const item = items.find((i) => i.id === itemId)
          if (!edge && item) {
            const newStartTime = time
            const duration = Math.abs(item.end_time - item.start_time)
            const newEndTime = newStartTime + duration
            setDraggingItem({
              ...item,
              start_time: newStartTime,
              end_time: newEndTime,
            })
          } else if (edge === 'right') {
            setDraggingItem({ ...item, end_time: time })
          } else if (edge === 'left') {
            setDraggingItem({ ...item, start_time: time })
          }
        }}
        useResizeHandle
        minResizeWidth={0}
        dragSnap={86400000}
      >
        <TimelineHeaders className='sticky'>
          <SidebarHeader>
            {({ getRootProps }) => {
              return (
                <div
                  className='flex !w-full items-center justify-between border-r border-gray5 px-2  lg:!w-[280px]'
                  {...getRootProps()}
                >
                  <div className='flex w-full items-center justify-between text-base font-medium'>
                    <button onClick={() => handleResetView()}>
                      {t('labels.today')}
                    </button>
                    {rangeChanged && (
                      <Button
                        fab
                        size='xs'
                        variant='outline'
                        icon={
                          <ArrowUUpLeft weight='bold' className='h-3 w-3' />
                        }
                        onClick={() => handleResetView()}
                      />
                    )}
                  </div>
                </div>
              )
            }}
          </SidebarHeader>
          <TimelineMarkers>
            <CustomMarker date={startOfDay(new Date())}>
              {({ styles }) => (
                <div
                  style={styles}
                  className='!z-[3] !w-[1.5px] !bg-brand !text-snow opacity-60'
                />
              )}
            </CustomMarker>
          </TimelineMarkers>
          <DateHeader unit={headerUnit} style={{ pointerEvents: 'none' }} />
          <DateHeader
            labelFormat='DD'
            unit='day'
            intervalRenderer={(props) => {
              const currentDate = new Date(
                props?.intervalContext?.interval?.startTime || ''
              )
              const dayOfTheYear = dayOfYear(currentDate)

              const isToday = isSameDay(currentDate, new Date())

              if (dayOfTheYear % timeSteps !== 0 && !isToday) return <></>

              return (
                <div
                  {...props?.getIntervalProps({
                    style: {
                      cursor: 'pointer',
                    },
                    onClick: isToday
                      ? () => handleResetView()
                      : () => handleResetView(currentDate),
                  })}
                >
                  <div
                    className={clsx('absolute mt-1 pt-0.5', {
                      'inset-0 z-[3] h-6 w-6 rounded-full !bg-brand text-snow shadow':
                        isToday,
                    })}
                    style={{
                      height: '25px',
                      width: '25px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: '-2px',
                      left: '-12px',
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    <span className='mb-0.5'>
                      {props?.intervalContext.intervalText}
                    </span>
                  </div>
                </div>
              )
            }}
          />
        </TimelineHeaders>
      </Timeline>
      {draggingItem && (
        <div className='-transition-x-1/2 absolute bottom-6 left-1/2 z-50 rounded bg-carbon p-2 text-snow'>
          <p>
            {formatDate(
              parseUnixTimeToDate(Number(draggingItem?.start_time) / 1000)
            )}{' '}
            -{' '}
            {formatDate(
              parseUnixTimeToDate(Number(draggingItem?.end_time) / 1000)
            )}
          </p>
        </div>
      )}
    </div>
  )
}
