import React, { useMemo } from 'react'

import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'

interface IPropTypes {
  onChange?: (value: string) => void
  value?: string
}
export default function RoadmapOrderingOption({ onChange, value }: IPropTypes) {
  const t = useTranslations('roadmap.ordering')

  const options = useMemo(
    () => [
      { label: t('startDate'), value: 'startDate' },
      { label: t('endDate'), value: 'endDate' },
      { label: t('newest'), value: 'newest' },
    ],
    []
  )
  return (
    <Select
      size='xs'
      value={options.filter((o) => o.value === value) || 'startDate'}
      options={options}
      onChange={(val) => onChange?.((val as ISelectOption).value)}
      placeholder={t('placeholder')}
    />
  )
}
