import { FolderOpen } from '@phosphor-icons/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import RoadmapGanttGroupItem from '@/components/roadmap/multipleRoadmap/RoadmapGanttGroupItem'
import UpgradeButton from '@/components/shared/components/module/billing/UpgradeButton'
import type {
  ITimelineGroupProps,
  ITimelineItem,
} from '@/components/shared/components/timeline/TimelineView'
import TimelineView from '@/components/shared/components/timeline/TimelineView'
import Button from '@/components/shared/ui/Button'
import EmptyState from '@/components/shared/ui/EmptyState'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import { ROADMAP_STATUS_LIST } from '@/config/appConstants'
import { settingsHelpLinks } from '@/config/staticURLs'
import HNContext from '@/context/HNContext'
import HNRoadmapListContext from '@/context/HNRoadmapListContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import {
  isSameObjectFullCheck,
  objectHasProperty,
  sortArrayOfObjects,
} from '@/lib/helpers/dataHelpers'
import {
  checkIfDateIsInFuture,
  formatDate,
  getTimeValue,
  parseJSONTimestamp,
  parseUnixTimeToDate,
} from '@/lib/helpers/dateHelpers'
import { iterateAndAddToIdxDB } from '@/lib/helpers/localStorageHelper'
import { roadmapTransformer } from '@/lib/helpers/modules/roadmapHelper'
import { templateComponentReplace } from '@/lib/helpers/stringHelpers'
import { getRoadmapsFromDB, listRoadmap, updateRoadmap } from '@/models/Roadmap'
import type { IRoadmap, IRoadmapListFilters } from '@/types/roadmap'

interface IPropTypes {
  readonly?: boolean
  filters?: IRoadmapListFilters
}

export default function RoadmapList({ readonly = false, filters }: IPropTypes) {
  const t = useTranslations('roadmap')
  const { organizationCustomization, organizationPlan } = useContext(HNContext)

  const [roadmaps, setRoadmaps] = useState<IRoadmap[]>([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const config = useContext(HNRoadmapListContext)
  const { isAdmin } = useContext(HNContext)

  const isDefaultRoadmapMultiple = Boolean(
    organizationCustomization?.roadmap_type === 'custom'
  )
  const isOrgPlanFreeway = Boolean(
    organizationPlan?.plan?.toLowerCase() === 'freeway'
  )
  const [emptyStateMessage, setEmptyStateMessage] = useState<string | null>(
    null
  )

  const fetchData = () => {
    getRoadmapsFromDB(filters || {})
      .then((_roadmaps) => {
        setRoadmaps(_roadmaps.map(roadmapTransformer))
        if (_roadmaps.length) {
          setLoading(false)
        }
      })
      .catch(() => setRoadmaps([]))
      .then(() => listRoadmap({ userSide: readonly }, filters))
      .then((data) => {
        const transformedRoadmap = data?.roadmaps.map(roadmapTransformer)
        iterateAndAddToIdxDB('ROADMAPS', transformedRoadmap)
        return transformedRoadmap
      })
      .then(setRoadmaps)
      .catch((err: Error) => setErrorMessage(err.message))
      .finally(() => setLoading(false))
  }

  const handleSort = (a: IRoadmap, b: IRoadmap) => {
    // Sort based on created_at
    if (config.sort === 'startDate' && a.start_date && b.start_date) {
      return (
        new Date(a?.start_date).getTime() - new Date(b?.start_date).getTime()
      )
    }
    if (config.sort === 'endDate' && a.end_date && b.end_date) {
      return new Date(a?.end_date).getTime() - new Date(b?.end_date).getTime()
    }
    if (config.sort === 'newest') {
      return new Date(b.create_at).getTime() - new Date(a.create_at).getTime()
    }
    return 0
  }

  const items = useMemo(() => {
    const roadmapItems = sortArrayOfObjects(
      roadmaps.sort(handleSort),
      'status'
    ).map((roadmap) => ({
      ...roadmap,
      title: roadmap.title,
      icon: roadmap.icon,
      group: roadmap.id,
      id: roadmap.id,
      color: roadmap?.icon?.color || '#ccc',
      start_time: roadmap.start_date
        ? getTimeValue(parseJSONTimestamp(roadmap.start_date))
        : undefined,
      end_time: roadmap.end_date
        ? getTimeValue(parseJSONTimestamp(roadmap.end_date))
        : undefined,
    }))
    const finalItems: ITimelineItem[] = []
    ROADMAP_STATUS_LIST.map((status) => {
      const itms = roadmapItems.filter((item) => item.status === status.value)
      if (itms.length) {
        finalItems.push({
          id: status.value,
          title: status.value,
          group: status.value,
          isGroup: true,
          status: status.value,
        })
        finalItems.push(...itms)
      }
      return status
    })
    return finalItems
  }, [roadmaps, config])

  const handleUpdate = (item: IRoadmap) => {
    setRoadmaps((prev) =>
      prev?.map((prevRoadmap) => {
        if (
          prevRoadmap.id === item.id &&
          !isSameObjectFullCheck(prevRoadmap, item)
        ) {
          return { ...prevRoadmap, ...item }
        }
        return prevRoadmap
      })
    )
  }

  const handleRealtimeEvent = (data: any) => {
    if (data?.entity === ENTITIES.ROADMAP) {
      const newRoadmap = data.data.data as IRoadmap
      if (data?.actionType === EVENT_ACTION_TYPES.UPDATE) {
        handleUpdate(newRoadmap)
      } else if (data?.actionType === EVENT_ACTION_TYPES.DELETE) {
        setRoadmaps((prev) => prev?.filter((item) => item.id !== newRoadmap.id))
      } else if (data?.actionType === EVENT_ACTION_TYPES.ADD) {
        setRoadmaps((prev) => {
          if (prev?.find((item) => item.id === newRoadmap.id)) {
            return prev
          }
          return [...prev, newRoadmap]
        })
      }
    }
  }

  const handleMove = (itemId: any, dragTime: number) => {
    const newStartDate = parseUnixTimeToDate(dragTime / 1000)

    const oldRoadmap = roadmaps.find((item) => +item.id === +itemId)
    if (!oldRoadmap || !oldRoadmap.start_date || !oldRoadmap.end_date) return
    const duration = Math.abs(
      new Date(oldRoadmap.end_date).getTime() -
        new Date(oldRoadmap.start_date).getTime()
    )
    const newEndDate = new Date(newStartDate.getTime() + duration)
    const payload = {
      start_date: newStartDate,
      end_date: newEndDate,
    }
    handleUpdate({
      ...oldRoadmap,
      ...payload,
    })
    updateRoadmap(itemId, payload).then((data) => {
      handleUpdate(data)
    })
  }
  const handleItemResize = (
    itemId: any,
    endTimeOrStartTime: number,
    edge: 'left' | 'right'
  ) => {
    const key = edge === 'left' ? 'start_date' : 'end_date'
    const newDate = parseUnixTimeToDate(endTimeOrStartTime / 1000)
    const oldRoadmap = roadmaps.find((item) => +item.id === +itemId)
    if (!oldRoadmap) return
    handleUpdate({ ...oldRoadmap, [key]: newDate })
    updateRoadmap(itemId, { [key]: newDate }).then((data) => {
      handleUpdate(data)
    })
  }

  const checkMultipleRoadmapShow = () => {
    if (
      !organizationPlan?.allow_multiple_roadmaps &&
      isDefaultRoadmapMultiple &&
      isOrgPlanFreeway
    )
      setEmptyStateMessage(
        t(
          'settings.multipleRoadmapTrial.notAllowDescriptionMessage.moveToFreewayWithMultipleRoadmap'
        )
      )
    else if (
      !organizationPlan?.allow_multiple_roadmaps &&
      isDefaultRoadmapMultiple &&
      objectHasProperty(organizationPlan?.trial_modules, 'multiple_roadmap') &&
      !checkIfDateIsInFuture(
        Number(organizationPlan?.trial_modules.multiple_roadmap?.end_date) *
          1000
      )
    )
      setEmptyStateMessage(
        t(
          'settings.multipleRoadmapTrial.notAllowDescriptionMessage.planWithTrialExpiry'
        )
      )
    else if (
      !organizationPlan?.allow_multiple_roadmaps &&
      isDefaultRoadmapMultiple
    )
      setEmptyStateMessage(
        t(
          'settings.multipleRoadmapTrial.notAllowDescriptionMessage.MoveToPlanWithMultipleRoadmap'
        )
      )
    else if (organizationPlan?.allow_multiple_roadmaps)
      setEmptyStateMessage(null)
  }

  useEffect(() => {
    fetchData()
    EventEmitter.subscribe('ENTITY_UPDATE', handleRealtimeEvent)
    return () => {
      EventEmitter.unsubscribe('ENTITY_UPDATE', handleRealtimeEvent)
    }
  }, [])

  useEffect(() => checkMultipleRoadmapShow(), [organizationCustomization])

  const renderGroup = (props: ITimelineGroupProps) => {
    return (
      <RoadmapGanttGroupItem
        {...props}
        onUpdate={handleUpdate}
        canEdit={!readonly}
      />
    )
  }

  if (emptyStateMessage) {
    const learnMoreComponent = (
      <UnstyledLink
        href={settingsHelpLinks.multipleRoadmapSwitchHelpArticle || ''}
        className='text-primary'
      >
        {t(
          'settings.multipleRoadmapTrial.notAllowDescriptionMessage.learnMoreText'
        )}
      </UnstyledLink>
    )
    const automaticAndKanbanTextComponent = (
      <Typography.Text className='font-bold'>
        {t(
          'settings.multipleRoadmapTrial.notAllowDescriptionMessage.automaticAndKanbanText'
        )}
      </Typography.Text>
    )
    const endDate = objectHasProperty(
      organizationPlan?.trial_modules,
      'multiple_roadmap'
    )
      ? formatDate?.(
          new Date(
            Number(
              organizationPlan?.trial_modules?.multiple_roadmap?.end_date
            ) * 1000
          )
        )
      : ''
    return (
      <EmptyState
        descriptionComponent={templateComponentReplace(emptyStateMessage, {
          endDate,
          learnMore: learnMoreComponent,
          automaticAndKanban: automaticAndKanbanTextComponent,
          br: <br></br>,
        })}
        actionComponent={
          !isOrgPlanFreeway ? (
            <UpgradeButton featureName='multiple_roadmap' />
          ) : null
        }
      >
        {!isOrgPlanFreeway && (
          <Typography.Text className='!text-gray-light'>
            {templateComponentReplace(
              t(
                'settings.multipleRoadmapTrial.notAllowDescriptionMessage.switchToDefaultRoadmapText'
              ),
              {
                learnMore: learnMoreComponent,
                automaticAndKanban: automaticAndKanbanTextComponent,
                br: <br></br>,
              }
            )}
          </Typography.Text>
        )}
      </EmptyState>
    )
  }

  if (loading) return <Spinner />
  if (errorMessage) return <EmptyState description={errorMessage} />

  if (!roadmaps?.length)
    return (
      <EmptyState
        icon={FolderOpen}
        title={t('messages.noRoadmaps.title')}
        description={
          isAdmin
            ? t('messages.noRoadmaps.description')
            : t('messages.noRoadmaps.publicDescription')
        }
        actionComponent={
          isAdmin && (
            <UnstyledLink href='/admin/r/new'>
              <Button size='xs'>{t('create.buttonText')}</Button>
            </UnstyledLink>
          )
        }
      />
    )

  return (
    <>
      <TimelineView
        items={items}
        groupRenderer={renderGroup}
        canResize={!readonly ? 'both' : false}
        canMove={!readonly}
        canChangeGroup={false}
        viewMode={config.viewMode}
        onItemResize={handleItemResize}
        onItemMove={handleMove}
      />
    </>
  )
}
