import { ArrowsClockwise, Lock, LockSimple } from '@phosphor-icons/react'
import React, { useContext, useMemo, useState } from 'react'

import RoadmapStatusPicker from '@/components/roadmap/multipleRoadmap/shared/RoadmapStatusPicker'
import IconPopover from '@/components/shared/components/IconPopover'
import type { ITimelineGroupProps } from '@/components/shared/components/timeline/TimelineView'
import CircleProgress from '@/components/shared/ui/CircleProgress/CirculeProgress'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/shared/ui/HoverCard/HoverCard'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { formatJSONTimestamp } from '@/lib/helpers/dateHelpers'
import {
  adminSideSingleSavedFilterPath,
  customRoadmapAdminPath,
  customRoadmapUserPath,
} from '@/lib/helpers/pathHelpers'
import { updateRoadmap } from '@/models/Roadmap'
import type { IKeyValueMap } from '@/types/common'
import type { IRoadmap } from '@/types/roadmap'

export default function RoadmapGanttGroupItem(props: ITimelineGroupProps) {
  const t = useTranslations('roadmap')
  const { group: _group, onUpdate, canEdit } = props
  const { embedType, isAdmin } = useContext(HNContext)
  const [group, setGroup] = useState(_group)

  const roadmapPath = useMemo(() => {
    let prefix = ''
    if (embedType === 'orgEmbed') {
      prefix = '/embed/home'
    } else if (embedType === 'moduleEmbed') {
      prefix = '/embed/roadmap'
    }
    if (isAdmin) return customRoadmapAdminPath(group.slug)
    return customRoadmapUserPath(group.slug, '', prefix)
  }, [group.slug, isAdmin, embedType])

  const handleUpdate = (
    data: IKeyValueMap,
    skipParentUpdate: boolean = false
  ) => {
    updateRoadmap(group.id, data).then((newRoadmap) => {
      if (skipParentUpdate) {
        setGroup(newRoadmap)
      } else if (onUpdate) onUpdate(newRoadmap)
    })
  }

  if (group.isGroup) {
    return (
      <div className='ml-[-4px] mr-[-3px] flex items-center justify-start space-x-2 truncate bg-gray4 px-2'>
        <RoadmapStatusPicker roadmap={group as IRoadmap} readonly />
        <span className='truncate font-medium'>
          {t(`status.${group.title}`)}
        </span>
      </div>
    )
  }

  return (
    <div className='flex cursor-pointer items-center justify-between space-x-1 truncate px-2'>
      <div className='flex w-[90%] grow items-center space-x-1'>
        <IconPopover
          icon={group?.icon}
          variant='naked'
          size='xxs'
          onChange={(iconData) => handleUpdate({ icon: iconData }, true)}
          readonly={!canEdit}
        />
        <HoverCard openDelay={500} open={isAdmin ? undefined : false}>
          <HoverCardTrigger className='truncate' asChild>
            <UnstyledLink
              href={roadmapPath}
              className='cusror-pointer flex grow items-center justify-between space-x-1 truncate'
            >
              <span className='truncate font-medium'>{group.title}</span>
              <span className='flex items-center space-x-1'>
                {!group.is_public && <LockSimple className='text-gray9 ' />}
              </span>
            </UnstyledLink>
          </HoverCardTrigger>
          <HoverCardContent>
            <>
              <div className='mb-1 flex items-center space-x-2'>
                <p className='text-sm font-medium text-gray11 '>
                  {group.title}
                </p>
                {!group.is_public && (
                  <span className='flex items-center space-x-1'>
                    <Lock />
                    <span>
                      {t('common.privacy.private', { ignorePrefix: true })}
                    </span>
                  </span>
                )}
                {group.mode === 'sync' && (
                  <UnstyledLink
                    target={'_blank'}
                    className='group rounded p-1 transition duration-200 hover:bg-gray5 '
                    href={adminSideSingleSavedFilterPath(
                      group.filter_view?.id || ''
                    )}
                  >
                    <ArrowsClockwise className='text-gray9  ' weight='bold' />
                  </UnstyledLink>
                )}
              </div>
              <p className='flex items-center space-x-2 text-xs text-gray9 '>
                {group.mode !== 'sync' && (
                  <>
                    <CircleProgress percentage={group.percentage} />
                    <span>
                      {group.percentage}% of {group.total_items_count || 0}
                    </span>
                    {Boolean(group.start_date || group.end_date) && (
                      <span className='text-gray12 dark:text-gray-light'>
                        •
                      </span>
                    )}
                  </>
                )}

                {Boolean(group.start_date || group.end_date) && (
                  <>
                    <span>
                      {group.start_date
                        ? formatJSONTimestamp(group.start_date)
                        : t('singleRoadmap.settings.startDate.noStartDate')}
                    </span>
                    <span>-</span>
                    <span>
                      {group.end_date
                        ? formatJSONTimestamp(group.end_date)
                        : t('singleRoadmap.settings.endDate.noEndDate')}
                    </span>
                  </>
                )}
              </p>
            </>
            {/* )} */}
          </HoverCardContent>
        </HoverCard>
      </div>
      <div className='flex items-center space-x-1.5'>
        <RoadmapStatusPicker
          roadmap={group as IRoadmap}
          onUpdate={handleUpdate}
          readonly={!canEdit}
        />
      </div>
    </div>
  )
}
